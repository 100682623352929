<template>
    <div class="col-12">
        <template v-if="comment">
            <!-- existing review -->
            <div class="bg-white grey-border p-4 mb-4 d-flex flex-column position-relative">
                <div class="row">
                    <div class="col-2 col-md-1">
                        <div class=" w-auto d-flex flex-column justify-content-center">
                            <span class="expert-detail-review-avatar align-self-center mp-rbk mp-font-weight-medium">{{ getUserInitials(creator) }}</span>
                        </div>
                    </div>
                    <div class="col-6 col-md-9 d-flex expert-detail-review-border-bottom">
                        <span class="expert-detail-review-name mp-rbk align-self-center">{{creator.firstname}} {{creator.lastname}}</span>
                    </div>
                    <div class="col-4 col-md-2 d-flex justify-content-end expert-detail-review-border-bottom">
                        <span class="expert-detail-review-date mp-qks align-self-center">{{ dateFormat(comment.created_at, 'DD.MM.YYYY') }}</span>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-11 offset-1">
                        <p class="expert-detail-review-text mp-qks">{{comment.msg}}</p>
                    </div>
                </div>

                <div class="row">
                    <div class="col-11 offset-1">
                        <p>
                            <!-- comment -->
                            <a
                               v-if="isEditEnabled && !comment.reply"
                               class="mp-green mp-qks mp-font-weight-semi-bold font-size-account-expert-comment-review-link account-expert-comment-review-link"
                               style="padding-right: 10px"
                               data-bs-toggle="collapse"
                               :href="`#review-${comment.id}-collapse`"
                               aria-expanded="false"
                               :aria-controls="`review-${comment.id}-collapse`"
                            >
                                Kommentieren
                            </a>
                            <!-- delete / report -->
                            <template v-if="isDeleteEnabled">
                                <a
                                    v-if="comment.creator.id === profile.id"
                                    class="text-danger mp-qks mp-font-weight-semi-bold font-size-account-expert-comment-review-link account-expert-comment-review-link"
                                    href="#"
                                    @click="doDelete">
                                    Löschen
                                </a>
                                <a
                                    v-else
                                    class="text-danger mp-qks mp-font-weight-semi-bold font-size-account-expert-comment-review-link account-expert-comment-review-link"
                                    href="#"
                                    @click="doReport">
                                    Melden
                                </a>
                            </template>
                        </p>
                    </div>

                </div>
            </div>

            <!-- editor -->
            <div class="row" v-if="isEditEnabled && !comment.reply">
                <div class="col-12">
                    <div class="collapse" :id="`review-${comment.id}-collapse`">
                        <div class="card card-body border-0">
                            <span class="d-block mb-3 mp-rbk mp-font-weight-medium font-size-account-section-title">Erfahrungsbericht kommentieren</span>
                            <textarea
                                class="form-control expert-detail-review-text-input"
                                id="review-1-comment-text"
                                rows="5"
                                placeholder="Schreibe deinen Kommentar ..."
                                v-model="reply"
                            ></textarea>
                            <div class="row mt-3 text-end">
                                <template v-if="reply.length > 0" >
                                    <div class="col-12 col-md-12 col-lg-12">
                                        <button
                                            type="button"
                                            class="btn btn-mp-red-subscribe font-size-registration-expert-btn-submit-comment mp-font-weight-medium py-2 px-5 me-2"
                                            @click="doDeleteReply()"
                                        >
                                            Löschen
                                        </button>
                                        <button
                                            type="button"
                                            class="btn btn-mp-green font-size-registration-expert-btn-submit-comment mp-font-weight-medium py-2"
                                            @click="doSave()"
                                        >
                                            Veröffentlichen
                                        </button>
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="col-12 col-md-6 offset-md-6 col-lg-4 offset-lg-8 d-flex flex-column">
                                        <button
                                            type="button"
                                            class="btn btn-mp-green font-size-registration-expert-btn-submit-comment mp-font-weight-medium py-2 px-5"
                                            @click="doSave()"
                                        >
                                            Veröffentlichen
                                        </button>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- review: reply -->
            <div class="row mt-2" v-if="comment.reply">
                <div class="col-12 col-md-11 offset-md-1">
                    <div
                        class="bg-white grey-border p-4 mb-4 d-flex flex-column position-relative">
                        <div class="row">
                            <div class="col-2 col-md-1">
                                <div class=" w-auto d-flex flex-column justify-content-center">
                                    <span class="expert-detail-review-avatar align-self-center mp-rbk mp-font-weight-medium">{{ getUserInitials(expert) }}</span>
                                </div>
                            </div>
                            <div class="col-6 col-md-9 d-flex expert-detail-review-border-bottom">
                                <span class="expert-detail-review-name mp-rbk align-self-center">{{expertName}}</span>
                            </div>
                            <div class="col-4 col-md-2 d-flex justify-content-end expert-detail-review-border-bottom">
                                <span class="expert-detail-review-date mp-qks align-self-center">{{ dateFormat(comment.updated_at, 'DD.MM.YYYY') }}</span>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-11 offset-1">
                                <template v-if="isEditEnabled">
                                    <textarea
                                        v-if="isEditEnabled"
                                        class="form-control expert-detail-review-text-input"
                                        rows="5"
                                        :value="comment.reply"
                                        @change="reply = $event.target.value"
                                    ></textarea>
                                    <div class="row mt-3 text-end">
                                        <div v-if="reply.length > 0" class="col-12 col-md-12 col-lg-12">
                                            <button
                                                type="button"
                                                class="btn btn-mp-red-subscribe font-size-registration-expert-btn-submit-comment mp-font-weight-medium py-2 px-5 me-2"
                                                @click="doDeleteReply()"
                                            >
                                                Löschen
                                            </button>
                                            <button
                                                type="button"
                                                class="btn btn-mp-green font-size-registration-expert-btn-submit-comment mp-font-weight-medium py-2"
                                                @click="doSave()"
                                            >
                                                Veröffentlichen
                                            </button>
                                        </div>
                                    </div>
                                </template>
                                <p class="expert-detail-review-text mp-qks" v-else>{{comment.reply}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <template v-else>
            <!-- new review -->
            <!-- editor -->
            <div class="row" v-if="isAuth">
                <div class="col-12">
                        <div class="card card-body border-0">
                            <span class="d-block mb-3 mp-rbk mp-font-weight-medium font-size-account-section-title">Erfahrungsbericht verfassen</span>
                            <!-- open description button -->
                            <button type="button" class="btn expert-detail-open-description justify-content-center text-center form-shadow" data-bs-toggle="modal" data-bs-target="#info-modal">
                                <i class="fas fa-info"></i>
                            </button>

                            <textarea
                                class="form-control expert-detail-review-text-input"
                                rows="5"
                                placeholder="Schreibe deinen Erfahrungsbericht..."
                                v-model="review"
                            ></textarea>
                            <div class="row mt-3">
                                <div class="col-12 col-md-6 offset-md-6 col-lg-4 offset-lg-8 d-flex flex-column">
                                    <button
                                        type="button"
                                        class="btn btn-mp-green font-size-registration-expert-btn-submit-comment mp-font-weight-medium py-2 px-5"
                                        @click="doCreate()"
                                    >
                                        Veröffentlichen
                                    </button>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
        </template>

        <!-- description modal -->
        <div class="modal fade" id="info-modal" tabindex="-1" data-bs-backdrop="static" aria-labelledby="info-modal-title" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-fullscreen-md-down modal-lg">
                <div class="modal-content p-4">
                    <div class="modal-header border-0 p-0 d-flex justify-content-center">
                        <span class="modal-title expert-detail-fields-of-experience-description-header mp-rbk mp-font-weight-semi-bold" id="info-modal-title">Verhaltensregeln</span>
                    </div>
                    <div class="modal-body py-0">
                        <ul class="expert-detail-fields-of-experience-description-list px-0">
                            <li class="my-5">
                                <!-- <span class="field-of-expertise mp-qks mp-font-weight-medium font-size-field-of-expertise d-block mb-3"> Text #1 </span> -->
                                <p class="field-of-expertise-description mp-qks font-size-field-of-expertise-description">
                                    Schön, dass du einen Erfahrungsbericht verfassen möchtest. Bitte achte dabei auf die Wertschätzung deines Gegenübers. Beschreibe deine Erfahrung möglichst sachlich und anschließend deine persönliche Empfindung. Vergiss nicht, dass für jeden etwas anderes gut bzw. schlecht ist. Vermeide Schimpfwörter, sonst muss mergeplate.com deinen Bericht leider löschen.
                                </p>
                            </li>
                        </ul>
                    </div>
                    <div class="modal-footer d-flex flex-column justify-content-center border-0 p-0">
                        <button type="button" class="btn btn-mp-green font-size-btn-close-field-of-expertise-description mp-font-weight-medium py-2 px-5" data-bs-dismiss="modal">Schließen</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- /description modal -->
    </div>
</template>




<script>
    import {mixinBasics} from "../mixins/basics";


    export default {
        name: "Comment",

        mixins: [
            mixinBasics
        ],

        props: {
            comment: {
                type: Object,
                required: false,
                default: undefined
            }
        },

        data() {
            return {
                review: '',
                reply: ''
            };
        },

        computed: {
            creator() {
                if (this.comment) {
                    return this.comment.creator;
                }
                return undefined;
            },

            expert() {
                if (this.comment) {
                    return this.getExpertByUserId(this.comment.expert_user_id);
                }
                return undefined;
            },

            expertName() {
                if (this.comment) {
                    let title = this.expert.title ?? '';
                    let titleTrailing = this.expert.title_trailing ? `, ${this.expert.title_trailing}` : '';
                    return `${title} ${this.expert.firstname} ${this.expert.lastname}${titleTrailing}`;
                }
                return undefined;
            },

            isEditEnabled() {
                if (this.comment) {
                    return this.isAuth && this.profile.role === 'expert' && this.profile.id === this.comment.expert_user_id && this.profile.subscription?.level >= 2;
                }
                return undefined;
            },

            isDeleteEnabled() {
                if (this.comment) {
                    return this.isAuth && ((this.profile.role === 'expert' && this.profile.id === this.comment.expert_user_id) || this.profile.id === this.comment.creator.id);
                }
                return undefined;
            }
        },

        methods: {
            getUserInitials(user) {
                return user.firstname && user.lastname ? `${user.firstname ? user.firstname[0] : ''}${user.lastname ? user.lastname[0] : ''}`.toUpperCase() : '';
            },

            doSave() {
                this.$emit('save', this.reply);
            },

            doCreate() {
                this.$emit('create', this.review);
            },

            doDelete() {
                console.log('doDelete');
                this.$emit('delete');
            },

            doDeleteReply() {
                this.reply = '';
                this.doSave();
            },

            doReport() {
                console.log('doReport');
                this.$emit('report');
            },
        },

        created() {
            this.reply = this.comment ? this.comment.reply ?? '' : '';
        }
    }
</script>


<style scoped>

</style>