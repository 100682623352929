<template>
    <div>
        <!-- lightbox -->
        <div>
            <CoolLightBox
                :items="lightboxItems"
                :index="lightboxIdx"
                :effect="'fade'"
                @close="lightboxIdx = null">
            </CoolLightBox>
        </div>

        <!-- expert detail -->
        <div class="container-fluid bg-lightblue" v-if="!isLoading">
            <div class="row">
                <div class="col-12">

                    <div class="container">
                        <div class="row mt-5">

                            <!-- left side -->
                            <div class="col-12 col-lg-3 mb-2 px-0">
                                <!-- expert.media -->
                                <div class="row mb-2">
                                    <div class="col-12 form-rounded-left form-rounded-right">
                                        <img class="w-100 form-rounded-left form-rounded-right" :src="expert.media[0].url" v-if="expert.media[0]" @click="lightboxIdx = 0" />
                                    </div>
                                </div>
                                <div class="row mb-4" v-if="expert.media.length > 1">
                                    <VueSlickCarousel v-bind="settingsCarousel">
                                        <template v-for="(img, idx) in expert.media">
                                            <div v-if="idx > 0" :key="`exp_img_${idx}`">
                                                <img
                                                    style="object-fit: cover;"
                                                    height="200px"
                                                    :src="img.url"
                                                    @click="lightboxIdx = idx"
                                                />
                                            </div>
                                        </template>
                                    </VueSlickCarousel>
                                    <!--
                                    <template v-for="(img, idx) in expert.media">
                                        <div v-if="idx > 0" class="col-6 text-center mb-2" :key="`exp_img_${idx}`">
                                            <img
                                                style="float: left; width: 100%; height: 100px; object-fit: cover;"
                                                :src="img.url"
                                                @click="lightboxIdx = idx"
                                            />
                                        </div>
                                    </template>
                                    -->
                                </div>

                                <!-- expert-name & desc (mobile only) -->
                                <div class="row d-lg-none d-block mb-4">
                                    <div class="col-12">
                                        <h1 class="mp-rbk font-size-expert-detail-title mp-font-weight-semi-bold d-block mb-3">{{ expertName }}</h1>
                                        <h2 class="mp-qks font-size-expert-detail-intro mp-font-weight-medium mb-3">{{ expert.desc_brief }}</h2>
                                        <h2 class="mp-qks font-size-expert-detail-intro mp-font-weight-medium mb-3">Kassen: {{ insurances }}</h2>

                                        <div v-if="isAuth">
                                            <a href="#" class="btn btn-mp-black-expert-detail mp-qks mp-font-weight-medium" style="margin-right: 10px" @click="toggleFavorite">
                                                <i :class="isFavoriteExpert(expert.user_id) ? 'fas fa-heart' : 'far fa-heart'" style="margin-right: 3px"></i> {{isFavoriteExpert(expert.user_id) ? 'Entfernen' : 'Merken' }}
                                            </a>
                                            <!--
                                            <a href="#" class="btn btn-mp-black-expert-detail mp-qks mp-font-weight-medium"><i class="fas fa-share"></i> Teilen</a>
                                            -->
                                        </div>
                                    </div>
                                </div>

                                <!-- contact data -->
                                <div class="row">
                                    <div class="col-12">
                                        <div class="course-detail-contact-data bg-white p-4 grey-border form-rounded-left form-rounded-right">
                                            <!-- title -->
                                            <div class="row mb-3">
                                                <div class="col-12">
                                                    <span class="course-detail-contact-data-title mp-rbk mp-font-weight-medium font-size-course-detail-contact-data-title">Kontaktdaten</span>
                                                </div>
                                            </div>
                                            <!-- /title -->
                                            <!-- company name -->
                                            <div class="row mb-2" v-if="expert.company_title">
                                                <div class="col-2 col-md-1 col-lg-2 d-flex flex-column justify-content-center">
                                                    <i class="fas fa-home course-detail-contact-data-entry-icon align-self-center"></i>
                                                </div>
                                                <div class="col-10 col-md-11 col-lg-10">
                                                    <span class="mp-qks course-detail-contact-data-entry">{{ expert.company_title }}</span>
                                                </div>
                                            </div>
                                            <!-- /company name -->
                                            <!-- address -->
                                            <div class="row mb-2">
                                                <div class="col-2 col-md-1 col-lg-2 d-flex flex-column justify-content-center">
                                                    <i class="fas fa-map-marker-alt course-detail-contact-data-entry-icon align-self-center"></i>
                                                </div>
                                                <div class="col-10 col-md-11 col-lg-10">
                                                    <span class="mp-qks course-detail-contact-data-entry">{{ expert.company_address }}<br />{{ expert.company_zip }} {{ expert.company_city }}</span>
                                                </div>
                                            </div>
                                            <!-- /address -->
                                            <!-- phone -->
                                            <div class="row mb-2" v-if="expert.phone">
                                                <div class="col-2 col-md-1 col-lg-2 d-flex flex-column justify-content-center">
                                                    <i class="fas fa-phone-alt course-detail-contact-data-entry-icon align-self-center"></i>
                                                </div>
                                                <div class="col-10 col-md-11 col-lg-10">
                                                    <a class="text-decoration-none" :href="`tel:${expert.phone}`"><span class="mp-qks course-detail-contact-data-entry">{{ expert.phone }}</span></a>
                                                </div>
                                            </div>
                                            <!-- /phone -->
                                            <!-- email -->
                                            <div class="row mb-2" v-if="expert.email">
                                                <div class="col-2 col-md-1 col-lg-2 d-flex flex-column justify-content-center">
                                                    <i class="fas fa-envelope course-detail-contact-data-entry-icon align-self-center"></i>
                                                </div>
                                                <div class="col-10 col-md-11 col-lg-10" style="overflow-wrap: anywhere">
                                                    <a class="text-decoration-none" :href="`mailto:${expert.email}`"><span class="mp-qks course-detail-contact-data-entry ">{{ expert.email }}</span></a>
                                                </div>
                                            </div>
                                            <!-- /email -->
                                            <!-- homepage -->
                                            <div class="row mb-2" v-if="expert.web_url">
                                                <div class="col-2 col-md-1 col-lg-2 d-flex flex-column justify-content-center">
                                                    <i class="fas fa-globe course-detail-contact-data-entry-icon align-self-center"></i>
                                                </div>
                                                <div class="col-10 col-md-11 col-lg-10">
                                                    <a class="text-decoration-none" :href="expertWebUrl" target="_blank"><span class="mp-qks course-detail-contact-data-entry">{{ expertWebUrl.replace('https://', '') }}</span></a>
                                                </div>
                                            </div>
                                            <!-- /homepage -->

                                            <!-- social media -->
                                            <div class="row mt-3 justify-content-center">
                                                <div class="col-2 pt-1" v-if="expert.fb">
                                                    <div class="d-flex flex-column sm-icon py-1">
                                                        <a class="sm-icon-link align-self-center text-center d-block w-100 h-100" :href="expert.fb"><i class="fab fa-facebook-f"></i></a>
                                                    </div>
                                                </div>
                                                <div class="col-2 pt-1" v-if="expert.instagram">
                                                    <div class="d-flex flex-column sm-icon py-1">
                                                        <a class="sm-icon-link align-self-center text-center d-block w-100 h-100" :href="expert.instagram"><i class="fab fa-instagram-square"></i></a>
                                                    </div>
                                                </div>
                                                <div class="col-2 pt-1" v-if="expert.youtube">
                                                    <div class="d-flex flex-column sm-icon py-1">
                                                        <a class="sm-icon-link align-self-center text-center d-block w-100 h-100" :href="expert.youtube"><i class="fab fa-youtube"></i></a>
                                                    </div>
                                                </div>
                                                <div class="col-2 pt-1" v-if="expert.linkedin">
                                                    <div class="d-flex flex-column sm-icon py-1">
                                                        <a class="sm-icon-link align-self-center text-center d-block w-100 h-100" :href="expert.linkedin"><i class="fab fa-linkedin"></i></a>
                                                    </div>
                                                </div>
                                                <div class="col-2 pt-1" v-if="expert.twitter">
                                                    <div class="d-flex flex-column sm-icon py-1">
                                                        <a class="sm-icon-link align-self-center text-center d-block w-100 h-100" :href="expert.linkedin"><i class="fab fa-twitter"></i></a>
                                                    </div>
                                                </div>
                                                <div class="col-2 pt-1" v-if="expert.podcast">
                                                    <div class="d-flex flex-column sm-icon py-1">
                                                        <a class="sm-icon-link align-self-center text-center d-block w-100 h-100" :href="expert.podcast"><i class="fas fa-podcast"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- /social media -->
                                        </div>
                                    </div>
                                </div>
                                <!-- /contact data -->
                            </div>

                            <!-- right side -->
                            <div class="col-12 col-lg-9 py-0 mt-0">
                                <!-- expert-name & desc (desktop only) -->
                                <div class="row d-lg-block d-none mb-5">
                                    <div class="col-12">
                                        <h1 class="mp-rbk font-size-expert-detail-title mp-font-weight-semi-bold d-block mb-3">{{ expertName }}</h1>
                                        <h2 class="mp-qks font-size-expert-detail-intro mp-font-weight-medium mb-3">{{ expert.desc_brief }}</h2>
                                        <h2 class="mp-qks font-size-expert-detail-description mp-font-weight-regular mb-3">{{ insurances }}</h2>
                                        <div v-if="isAuth">
                                            <a href="#" class="btn btn-mp-black-expert-detail mp-qks mp-font-weight-medium" style="margin-right: 10px" @click="toggleFavorite">
                                                <i :class="isFavoriteExpert(expert.user_id) ? 'fas fa-heart' : 'far fa-heart'" style="margin-right: 3px"></i> {{isFavoriteExpert(expert.user_id) ? 'Entfernen' : 'Merken' }}
                                            </a>
                                            <!--
                                            <a href="#" class="btn btn-mp-black-expert-detail mp-qks mp-font-weight-medium"><i class="fas fa-share"></i> Teilen</a>
                                            -->
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <!-- Tabs navs -->
                                        <ul class="nav nav-tabs mb-3" id="edc" role="tablist">
                                            <li class="nav-item" role="presentation">
                                                <button class="nav-link active expert-detail-tab-title mp-rbk py-2 pe-4 ps-0 font-size-expert-detail-tab-title"
                                                        id="offers-tab"
                                                        data-bs-toggle="tab"
                                                        data-bs-target="#offers"
                                                        type="button" role="tab"
                                                        aria-controls="offers"
                                                        aria-selected="true">
                                                    ANGEBOTE
                                                </button>
                                            </li>
                                            <li class="nav-item" role="presentation">
                                                <button class="nav-link expert-detail-tab-title mp-rbk py-2 px-0 font-size-expert-detail-tab-title"
                                                        id="reviews-tab"
                                                        data-bs-toggle="tab"
                                                        data-bs-target="#reviews"
                                                        type="button" role="tab"
                                                        aria-controls="reviews"
                                                        aria-selected="false">
                                                    ERFAHRUNGSBERICHTE
                                                </button>
                                            </li>
                                        </ul>
                                        <!-- /Tabs navs -->

                                        <!-- Tabs content -->
                                        <div class="tab-content" id="edc-content">
                                            <div class="tab-pane fade show active" id="offers" role="tabpanel" aria-labelledby="offers-tab">
                                                <!-- offers tab content -->
                                                <div class="bg-white grey-border p-4 d-flex flex-column position-relative form-rounded-left form-rounded-right">
                                                    <span class="expert-detail-fields-of-expertise-title mp-rbk mp-font-weight-medium font-size-expert-detail-fields-of-expertise-title">Fachgebiete</span>
                                                    <!--
                                                    <ul class="expert-detail-fields-of-expertise-list pt-1 ps-0 mb-0">
                                                        <li class="py-1" v-for="a in expert.areas" :key="`${keyPrefix}_${a.id}_area`">
                                                            <span class="field-of-expertise-item font-size-field-of-expertise-item mp-qks mp-font-weight-medium"><i class="fas fa-check"></i> {{ a.title }} </span>
                                                        </li>
                                                    </ul>
                                                    -->

                                                    <!-- list: areas -->
                                                    <div class="row py-3" >
                                                        <div class="col-12 col-md-6 col-lg-4 py-1" v-for="a in expertAreas" :key="`${keyPrefix}_${a.id}_area`">
                                                            <span class="field-of-expertise-item font-size-field-of-expertise-item mp-qks mp-font-weight-medium"><i class="fas fa-check"></i>
                                                                 {{ a.title }} {{ a.in_training ? '(i.A.)' : ''}}
                                                            </span>
                                                        </div>
                                                    </div>

                                                    <!-- open description button -->
                                                    <button type="button" class="btn expert-detail-open-description justify-content-center text-center form-shadow" data-bs-toggle="modal" data-bs-target="#description-modal">
                                                        <i class="fas fa-info"></i>
                                                    </button>
                                                    <!-- /open description button -->

                                                    <!-- description modal -->
                                                    <div class="modal fade" id="description-modal" tabindex="-1" data-bs-backdrop="static" aria-labelledby="description-modal-title" aria-hidden="true">
                                                        <div class="modal-dialog modal-dialog-centered modal-fullscreen-md-down modal-lg">
                                                            <div class="modal-content p-4">
                                                                <div class="modal-header border-0 p-0 d-flex justify-content-center">
                                                                    <span class="modal-title expert-detail-fields-of-experience-description-header mp-rbk mp-font-weight-semi-bold" id="description-modal-title">Begriffserklärung</span>
                                                                    <!--                                                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>-->
                                                                </div>
                                                                <div class="modal-body py-0">

                                                                    <ul class="expert-detail-fields-of-experience-description-list px-0">
                                                                        <li class="mt-4">
                                                                            <span class="field-of-expertise-description mp-qks font-size-field-of-expertise-description">
                                                                                i.A. = Ausbildung - der Experte befindet sich aktuell noch in Ausbildung
                                                                            </span>
                                                                        </li>
                                                                        <li class="mt-4" v-for="a in expertAreas" :key="`${keyPrefix}_${a.id}_area_desc`">
                                                                            <span class="field-of-expertise mp-qks mp-font-weight-medium font-size-field-of-expertise d-block mb-3"> {{ a.title.toUpperCase() }} </span>
                                                                            <p class="field-of-expertise-description mp-qks font-size-field-of-expertise-description">{{ a.desc }}</p>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div class="modal-footer d-flex flex-column justify-content-center border-0 p-0">
                                                                    <button type="button" class="btn btn-mp-green font-size-btn-close-field-of-expertise-description mp-font-weight-medium py-2 px-5" data-bs-dismiss="modal">Schließen</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- /description modal -->
                                                </div>

                                                <!-- description -->
                                                <p class="expert-detail-description font-size-expert-detail-description mp-font-weight-regular mp-qks my-4">
                                                    <span v-html="nl2br(expert.desc, true, false)" />
                                                </p>
                                                <!-- /description -->

                                                <!-- courses -->
                                                <template v-if="expert.courses.length > 0">
                                                    <span
                                                        v-if="expert.subscription_level > 1"
                                                        class="expert-detail-my-courses-title font-size-expert-detail-my-courses-title mp-font-weight-semi-bold mp-rbk d-block mt-5 mb-3"
                                                    >Meine Angebote</span>

                                                    <div
                                                        class="row"
                                                        v-if="expert.subscription_level > 1"
                                                    >
                                                        <!-- course -->
                                                        <CourseCard :course="c" v-for="c in courses" :key="`${keyPrefix}_${c.id}_course`" />
                                                    </div>
                                                </template>
                                            </div>


                                            <!-- reviews tab content -->
                                            <div class="tab-pane fade" id="reviews" role="tabpanel" aria-labelledby="reviews-tab">
                                                <div class="row" v-if="!comments || comments.length === 0">
                                                    <p class="mp-qks font-size-expert-detail-intro mp-font-weight-medium text-center mt-5">
                                                        Es sind noch keine Erfahrungsberichte vorhanden.<br>
                                                        {{ isAllowToWriteReviews ? '' : 'Nur registrierte Benutzer können Erfahrungsberichte abgeben.' }}
                                                    </p>
                                                </div>
                                                <div class="row" v-else>
                                                    <Comment
                                                        v-for="c in comments" :key="c.id"
                                                        :comment="c"
                                                        @save="doUpdateComment(c.id, $event)"
                                                        @delete="doDeleteComment(c.id)"
                                                        @report="doReportComment(c.id)"
                                                    />
                                                </div>
                                                <div class="row mb-5" v-if="isAllowToWriteReviews">
                                                    <Comment @create="doCreateComment($event)" />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- /expert detail -->
        <!-- /content -->
    </div>
</template>



<script>
    import {mixinBasics} from "../../mixins/basics";
    import CourseCard from "../../components/CourseCard";
    import {apiCall, buildFinalUrl} from "../../helpers";
    import Configs from "../../configs";
    import {mapActions} from "vuex";
    import Comment from "../../components/Comment";
    import CoolLightBox from 'vue-cool-lightbox';
    import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';
    //import {gmapsMap, gmapsMarker} from 'x5-gmaps';

    import VueSlickCarousel from 'vue-slick-carousel'
    import 'vue-slick-carousel/dist/vue-slick-carousel.css'
    // optional style for arrows & dots
    import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'


    const api = Configs.restApi.endpoints.map;


    export default {
        name: "ExpertDetail",

        metaInfo()
        {
            console.log('metaInfo');
            return {
                title: this.metaTitle,
                meta: [
                    {name: 'description', content: `${this.metaTitle} - ${this.metaDesc} auf mergeplate.com - Informationen, Angebote und Erfahrungsberichte`},

                ]
                /*
                    .concat(this.buildOpenGraphMetaTags(
                        `${this.metaTitle} | mergeplate.com`,
                        `${this.metaTitle} - ${this.metaDesc} auf mergeplate.com - Informationen, Angebote und Erfahrungsberichte`,
                        this.$route.path,
                        this.expert.media[0] ? this.expert.media[0].url : undefined
                    ))
                */
            };
        },

        components: {
            Comment,
            CourseCard,
            CoolLightBox,
            VueSlickCarousel
            //gmapsMap,
            //gmapsMarker
        },

        mixins: [
            mixinBasics
        ],

        props: {
            id: {
                required: true
            },
            // SEO meta-crap
            metaExpert: {
                type: String,
                required: false
            },
        },

        data() {
            return {
                keyPrefix: 'expD',
                isLoading: true,
                comments: undefined,
                lightboxIdx: null,
                images: [],
                settingsCarousel: {
                    "dots": true,
                    "arrows": true,
                    "dotsClass": "slick-dots custom-dot-class",
                    "edgeFriction": 0.35,
                    "infinite": false,
                    "speed": 500,
                    "slidesToShow": 1,
                    "slidesToScroll": 1,
                    //"centerMode": false,
                    //"centerPadding": '5px',
                    "adaptiveHeight": true,
                    "variableWidth": true,
                }
            };
        },

        computed: {
            expert() {
                return this._experts.find(e => e.id === parseInt(this.id));
            },

            expertName() {
                return `${this.expert.title ? this.expert.title : ''} ${this.expert.firstname} ${this.expert.lastname}${this.expert.title_trailing ? ', ' + this.expert.title_trailing : ''}`
            },

            courses() {
                return this._courses.filter(c => c.user_id === this.expert.user_id);
            },

            expertAreas() {
                return this.expert.areas.filter(a => a.verified);
            },

            expertWebUrl() {
                if (!this.expert.web_url) {
                    return;
                }
                let url = this.expert.web_url.toLowerCase();
                return (url.indexOf('https:') < 0 && url.indexOf('http:') < 0) ? `https://${url}` : url;
            },

            lightboxItems() {
                return this.expert.media.map(m => m.url);
            },

            isAllowToWriteReviews() {
                if (this.isAuth) {
                    let expertProfile = this.getExpertByUserId(this.expert.user_id);
                    let ownProfile = this.getExpertByUserId(this.profile.id);
                    return this.profile.role === 'user' || (this.profile.role === 'expert' && (!ownProfile || expertProfile.id !== ownProfile.id));
                }
                return false;
            },

            metaTitle() {
                return this.expertName;
            },

            metaDesc() {
                return this.expert.desc_brief;
            },

            insurances() {
                return this.expert.insurances.length > 0 ?  'Kassen: ' + this.expert.insurances.map(e => e.title).join(', ') : '';
            }
        },

        methods: {
            ...mapActions('main', {
                _loadUserProfile: 'loadUserProfile',
            }),

            async getComments() {
                let req = api.comment.index;
                let res = await apiCall(buildFinalUrl(req.url, {'expertUserId': this.expert.user_id}), req.method);
                this.comments = res.data;
                this.isLoading = false;
            },

            async toggleFavorite() {
                let favs = this.isFavoriteExpert(this.expert.user_id) ? this.favRemove() : this.favAdd();
                await this.doUserFavoritesSave(favs);
            },

            favAdd() {
                let res = this.profile.favoriteExperts.map(e => e.user_id);
                res.push(this.expert.user_id);
                return res;
            },

            favRemove() {
                return this.profile.favoriteExperts.filter(e => e.user_id !== this.expert.user_id).map(e => e.user_id);
            },

            async doUserFavoritesSave(favs) {
                let payload = {favorite_experts: favs};
                let req = api.user.update;
                await apiCall(req.url, req.method, payload, false);
                await this._loadUserProfile(true);
            },

            async doCreateComment(review) {
                let req = api.comment.create;
                await apiCall(req.url, req.method, {expert_user_id: this.expert.user_id, msg: review});
                await this.getComments();
            },

            async doUpdateComment(commentId, reply) {
                let req = api.comment.update;
                await apiCall(buildFinalUrl(req.url, {'id': commentId}), req.method, {reply: reply});
                await this.getComments();
            },

            async doDeleteComment(commentId) {
                let req = api.comment.delete;
                await apiCall(buildFinalUrl(req.url, {'id': commentId}), req.method);
                await this.getComments();
            },

            async doReportComment(commentId) {
                let req = api.comment.update;
                await apiCall(buildFinalUrl(req.url, {'id': commentId}), req.method, {report: 1});
                await this.getComments();
            },
        },

        async created() {
            console.log('created');
            //this.images = await this.preloadImages(this.expert.media.map(e => e.url));
            await this.getComments()
        },

        mounted() {
            console.log('mounted');
            window.scrollTo(0,0);
        }
    }
</script>


<style>
    img {
        cursor: pointer;
    }

    .map {
        width: 100%;
        height: 220px;
        display: inline-block;
        max-height: 100%;
        overflow: auto;
        border: none;
        background-color: rgb(229, 227, 223);
    }

    #container img
    {
        max-width: 100%;
        max-height: 100%;
    }

    .slick-prev {
        position: absolute;
        left: 14px !important;
        z-index: 999;
    }

    .slick-next {
        position: absolute;
        right: 14px !important;
        z-index: 999;
    }
</style>
